import { MenuItem } from "@mui/material";
import DropDown from "components/inputs/DropDown";
import TextButton from "components/inputs/TextButton";
import { useState } from "react";
import { useSetRecoilState } from "recoil";
import { alertAtom } from "recoil/atoms/alert";
import styled from "styled-components";

interface Types {
  setShowUpload: Function;
}

const UploadFileForm = styled.form`
  display: flex;
  margin: 0px 10px;
  flex-direction: column;

  .upload-styles {
    display: flex;
    align-items: center;
  }

  .submit {
    display: flex;
    justify-content: flex-end;
  }
`;

const ResourceTableComponent = ({ setShowUpload }: Types) => {
  const [selectedFileType, setSelectedFileType] = useState("functional_specification");
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const setAlert = useSetRecoilState(alertAtom);
  const [loading, setLoading] = useState(false);

  const fileChange = (e: any) => {
    e.persist();
    setSelectedFile(e.target.files[0]);
  };

  const submitFile = (e: any) => {
    e.preventDefault();
    setLoading(true);

    if (selectedFile !== null) {
      const newFileName = `${selectedFileType}.${selectedFile.name.split(".").pop()}`;
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("filename", newFileName);

      fetch(`/api/v1/resource/upload`, {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          response.json();
        })
        .then((data) => {
          setAlert({
            type: "success",
            message: "File has been uploaded successfully",
            timeout: 5000,
          });
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setAlert({
            type: "error",
            message: "Error uploading file, please try again",
            timeout: 5000,
          });
          setLoading(false);
        });
    }
  };

  const FileTypes = [
    { title: "Reconciliation 101", value: "reconciliation_101" },
    { title: "Balancing Area Notification Form And Guide", value: "balancing_area_notification_form_and_guide" },
    { title: "Profile Notification Form And Guide", value: "profile_notification_form_and_guide" },
    { title: "Residual Volume ICP Notification Form And Guide", value: "residual_volume_icp_notification_form_and_guide" },
    { title: "Trading Notification Form Guide", value: "trading_notification_form_guide" },
    { title: "Trading Notification Form", value: "trading_notification_form" },
    { title: "User Manual", value: "user_manual" },
    { title: "File Names And Formats", value: "file_names_and_formats" },
    { title: "Gifted Generation List", value: "gifted_generation_list" },
    { title: "Functional Specification", value: "functional_specification" },
  ];

  interface filetypes {
    title: string;
    value: string;
  }

  return (
    <UploadFileForm onSubmit={submitFile}>
      <DropDown
        onchange={(e: any) => {
          setSelectedFileType(e.target.value);
        }}
        required={true}
        value={selectedFileType}
        id={"part_code"}
        label={"Participant"}
        items={FileTypes.map((filetype: filetypes, index: number) => (
          <MenuItem key={index} value={filetype.value}>
            {filetype.title}
          </MenuItem>
        ))}
      />
      <div className={"upload-styles"}>
        <TextButton title={"Upload File"} component={"label"} type={"button"}>
          <input type="file" accept=".csv" hidden onChange={fileChange} />
        </TextButton>

        {selectedFile ? `${selectedFile.name}` : "No File Chosen"}
      </div>
      <div className={"submit"}>
        <TextButton type={"submit"} title={"Submit"} loading={loading} disabled={selectedFile === null} onClick={() => setShowUpload(false)} />
      </div>
    </UploadFileForm>
  );
};

export default ResourceTableComponent;
