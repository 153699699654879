import styled from "styled-components";
import ResourceTableComponent from "pages/resources/ResourceTableComponent";
import { headerAtom } from "recoil/atoms/header";
import { useSetRecoilState } from "recoil";
import { useEffect, useState } from "react";
import { getUser } from "utils/helpers/CookiesHelper";
import IconButton from "components/inputs/IconButton";
import { FileUpload } from "@mui/icons-material";
import { themeAtom } from "recoil/atoms/theme";
import { useRecoilValue } from "recoil";
import Modal from "components/modal/Modal";
import Upload from "pages/resources/Upload";

const ResourceContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow-y: auto;
  color: ${({ theme }) => theme.textPrimary};

  .resource-info {
    padding: 1rem;
    width: 75vw;
    box-sizing: border-box;
    background: ${({ theme }) => theme.clearWhite};

    box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
    margin: 2rem 0;
  }
`;

const StyledResourceTable = styled.div`
  border-collapse: collapse;
  width: 75vw;
  background: ${({ theme }) => theme.clearWhite};
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;

  table {
    padding: 1rem;
    width: 100%;
  }

  th {
    text-align: left;
    background-color: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.textSecondary};
  }
  td,
  th {
    padding: 0.2rem;
  }

  @media only screen and (max-width: 500px) {
    .hide-col {
      display: none;
    }
  }
`;

const UploadContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Resources = () => {
  const setHeader = useSetRecoilState(headerAtom);
  const [showUpload, setShowUpload] = useState(false);
  const theme = useRecoilValue(themeAtom);

  useEffect(() => {
    setHeader({
      title: "Resources",
      description: "",
    });
  }, [setHeader]);

  return (
    <ResourceContainer>
      <div className={"resource-info"}>
        The below documents are designed to assist with fulfilling your reconciliation obligations. They include:
        <ul>
          <li>reference material to better navigate the portal and to ensure a better understanding of the complexities of electricity volume reconciliation.</li>
          <li>forms required to notify the Reconciliation Manager of specific changes.</li>
        </ul>
        If you require further help or information please get in touch.
      </div>

      <StyledResourceTable>
        {getUser().admin && (
          <UploadContainer>
            <IconButton icon={<FileUpload />} disabled={false} color={theme.primary} onClick={() => setShowUpload(true)} />
          </UploadContainer>
        )}
        <table>
          <tbody>
            <tr>
              <th>Title</th>
              <th className={"hide-col"}>Summary</th>
              <th>Download</th>
            </tr>
            <ResourceTableComponent title={"Reconciliation 101"} summary={"Overview of the reconciliation processes."} downloadLink={"/test_location/reconciliation_101.pdf"} />
            <ResourceTableComponent
              title={"Balancing Area Notification Form and Guide"}
              summary={"Distributors to provide Balancing Area information."}
              downloadLink={"/files/balancing_area_notification_form_and_guide.pdf"}
            />
            <ResourceTableComponent
              title={"Profile Notification Form and Guide"}
              summary={"Profile Owners to provide the RM with profile information."}
              downloadLink={"/files/profile_notification_form_and_guide.pdf"}
            />
            <ResourceTableComponent
              title={"Residual Volume ICP Notification Form and Guide"}
              summary={"Embedded Network owners to provide SB ICP."}
              downloadLink={"/files/residual_volume_icp_notification_form_and_guide.pdf"}
            />
            <ResourceTableComponent title={"Trading Notification Form Guide"} summary={"Information pertaining to Trade Notifications."} downloadLink={"/files/trading_notification_form_guide.pdf"} />
            <ResourceTableComponent title={"Trading Notification Form"} summary={"Participants to notify the RM of new Trading Notifications."} downloadLink={"/files/trading_notification_form.pdf"} />
            <ResourceTableComponent title={"User Manual"} summary={"General information regarding interacting with the reconciliation manager."} downloadLink={"/files/user_manual.pdf"} />
            <ResourceTableComponent title={"File Names and Formats"} summary={"Formats and information regarding submission and result files."} downloadLink={"/files/file_names_and_formats.pdf"} />
            <ResourceTableComponent
              title={"Gifted Generation Register"}
              summary={"Central Register of Gifted Generation ICPs. Contact us to revise or add to."}
              downloadLink={"/files/gifted_generation_list.xlsx"}
            />
            <ResourceTableComponent title={"Functional Specification "} summary={"Reconciliation Manager Functional Details."} downloadLink={"/files/functional_specification.pdf"} />
          </tbody>
        </table>
      </StyledResourceTable>

      <Modal onClose={() => setShowUpload(false)} open={showUpload} width={"30vw"}>
        <Upload setShowUpload={setShowUpload} />
      </Modal>
    </ResourceContainer>
  );
};
export default Resources;
