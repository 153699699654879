import styled from "styled-components";
import Carousel from "pages/home/Carousel";
import { useState, useEffect } from "react";
import LoadingSpinner from "components/loaders/LoadingSpinner";
import { useSetRecoilState } from "recoil";
import { alertAtom } from "recoil/atoms/alert";
import { headerAtom } from "recoil/atoms/header";
import nzxLogo from "assets/images/logo-nzx.png";
import eaLogo from "assets/images/ea-logo.png";
import { getData } from "utils/helpers/FetchHelper";
import background from "assets/images/home-image.jpg";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow: auto;
  justify-content: flex-start;
  font-family: Open-Sans, Helvetica, Sans-Serif;
  background-image: url(${background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;

  p {
    margin: 0.5rem 0;
  }

  .info {
    padding: 1.5rem 3rem;
    width: 75vw;
    background: ${({ theme }) => theme.clearWhite};
    margin-top: 3rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .home-header {
      padding-bottom: 0.5rem;
      display: flex;
      justify-content: center;
      font-weight: bold;
      font-size: 1.5rem;

      @media (max-width: 500px) {
        font-size: 1rem;
      }
    }

    .home-logos {
      display: flex;

      .nzx {
        margin-top: auto;
        margin-bottom: auto;
        height: 2rem;
        padding: 0.2rem;
      }

      .ea {
        height: 4rem;
        padding: 0.2rem;
      }
    }

    .dislcaimer {
      font-size: 8px;
    }
  }

  .carousel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 75vw;
    margin: 3rem 0;
    background: ${({ theme }) => theme.clearWhite};
    box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
    box-sizing: content-box;
  }

  .carousel * {
    box-sizing: content-box;

    @media (max-width: 1000px) {
      display: none;
    }
  }
`;

const Home = () => {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const setAlert = useSetRecoilState(alertAtom);
  const setHeader = useSetRecoilState(headerAtom);

  useEffect(() => {
    setHeader({
      title: "Home",
      description: "Welcome to the Reconciliation Manager Portal",
    });
    getData(`/api/v1/news/top_ten_news`, setNews, setLoading, setAlert);
  }, [setAlert, setHeader]);

  return (
    <StyledContainer>
      <div className={"info"}>
        <div className={"home-header"}>Welcome to the Reconciliation Manager Portal</div>
        <p>
          NZX performs the services of Reconciliation Manager for New Zealand's electricity market. This portal provides reconciliation participants the facility to check and upload submission
          information and download results and other associated reports. It contains resources aimed to assist participants to fulfill their reconciliation obligations including information pertaining
          to the various files and formats, a user manual and other educational material.
        </p>
        <p>
          Should you have any issues with access, submissions, understanding results or any other general queries, the reconciliation team is here to help. Please use the Contact Us form within and
          get in touch.
        </p>
        <div className="home-logos">
          <img className="nzx" src={nzxLogo} alt="NZXLogo" />
          <img className="ea" src={eaLogo} alt="EALogo" />
        </div>

        <p className="dislcaimer">This site collects analytics to monitor site performance and provide better user experience.</p>
      </div>
      <div className={"carousel"}>
        {loading && <LoadingSpinner />}
        {news.length > 1 && <Carousel data={news} />}
      </div>
    </StyledContainer>
  );
};
export default Home;
